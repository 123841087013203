<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Periodos de complementos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                        @loadModalData="loadModalData"
                    >
                        <template slot="actionButtonsLeft">
                            <template v-if="permisos.create">
                                <div class="botonAgregar" v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                    <v-btn
                                        rounded
                                        color="#004BAF"
                                        class="btnAdd"
                                        right
                                        @click="abrirModal('add')"
                                    >
                                        <v-icon dark class="mr-2">add</v-icon>Agregar
                                        <div class="mr-4"></div>
                                    </v-btn>
                                </div>
                            </template>
                            
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_value"
                                    class="pa-0 ma-0">
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresas"
                                    :items="itemsEmpresas"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empresa_value"
                                    class="pa-0 ma-0">
                                </v-autocomplete>
                            </v-col>                                            
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de nómina"
                                    :items="itemsTiposNominas"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="tipo_nomina_id_busqueda"
                                    class="pa-0 ma-0">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Periodo"
                                    :items="itemsPeriodos"
                                    item-text="numero"
                                    item-value="id"
                                    persistent-hint
                                    v-model="periodo_id_busqueda"
                                    class="pa-0 ma-0">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="inicio_captura"
                                    label="Inicio de captura"
                                    placeholder="dd/mm/aaaa"
                                    ref="BusquedaFechaInicio"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="final_captura"
                                    label="Fin de captura"
                                    placeholder="dd/mm/aaaa"
                                    ref="BusquedaFechaFin"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>{{item.periodo}} - {{item.tipoNomina.descripcion}} </td>
                                <td><div class="tblPrincipal">{{dateFormat(item.inicio_captura)}}</div></td>
                                <td><div class="tblPrincipal">{{dateFormat(item.final_captura)}}</div></td>
                                <td><div v-if="item.comentarios != null">{{item.comentarios}}</div> </td>
                                <td>
                                    <div class="tblOpciones" v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAcciones"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="vista_incidencias(item)"
                                                >
                                                    <v-list-item-title>Complementos</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    @click="historico(item)"
                                                >
                                                    <v-list-item-title>Histórico</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.estatus !== 'AUTORIZADO' && item.estatus !== 'RECHAZADO' && !item.periodoModel.cerrado"
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <template v-if="permisos.delete">
                                                    <v-list-item
                                                        v-if="item.estatus !== 'AUTORIZADO' && item.estatus !== 'RECHAZADO'"
                                                        @click="eliminar(item)" 
                                                    >
                                                        <v-list-item-title>Eliminar</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                
                                                
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingIncidencias">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingIncidencias" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Clientes"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>  
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresas" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresas"
                                                        :items="itemsEmpresas"
                                                        v-model="empresa_value"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        required 
                                                        :error-messages="errors"
                                                        persistent-hint
                                                    ></v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>                                      
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo nómina" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo de nómina"
                                                        :items="itemsTiposNominas"
                                                        item-text="claveDescripcion"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="tipo_nomina_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Periodo"
                                                        :items="itemsPeriodos"
                                                        item-text="numero"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="periodo_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Periodo de complementos" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Periodo de complementos" 
                                                        class="pa-0 ma-0" 
                                                        v-model="periodoIncidencia.periodo" 
                                                        :error-messages="errors" 
                                                        required
                                                        disabled
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodoIncidencia.inicio_captura"
                                                    label="Inicio de captura"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaCaptura"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="periodoIncidencia.final_captura"
                                                    label="Fin de captura"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaFinCaptura"
                                                />
                                            </v-col>
                                            
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    v-model="modelDepartamentos"
                                                    :items="itemsDepartamentos"
                                                    outlined
                                                    chips
                                                    deletable-chips
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Departamentos"
                                                    multiple
                                                    @change="cambio_departamento()"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Usuario que captura" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Usuario que captura"
                                                        :items="itemsUsuariosCapturan"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="periodoIncidencia.usuario_captura_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Usuario que autoriza" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Usuario que autoriza"
                                                        :items="itemsUsuariosAutorizan"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="periodoIncidencia.usuario_autoriza_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-textarea
                                                        v-model="periodoIncidencia.comentarios"
                                                        label="Descripción"
                                                        outlined
                                                        rows="2"
                                                        :error-messages="errors" 
                                                        required
                                                    ></v-textarea>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <template v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')">
                                        <v-btn
                                            class="btnGuardar"
                                            @click="guardar()" 
                                            :disabled="invalid" 
                                            :loading="isSaving"
                                            v-if="!estatus"
                                        >
                                            Guardar
                                        </v-btn>
                                    </template>
                                   
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <v-dialog v-model="dialogHistorico" persistent max-width="950px" class="borde-card">
                <v-card class="borde-card">
                    <div>
                        <v-btn
                            @click="cerrarModalHistorico()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar modal-pull-right"
                            absolute
                            top
                            right
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                    </div>
                    <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Histórico del periodo de complementos</h2>
                        </div>
                    </v-card-title>
                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoadingHistorico">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoadingHistorico" grid-list-md class="pa-0">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-historico">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px;" class="pl-7 historicoth">Estatus</th>
                                                <th class="historicoth">Responsable</th>
                                                <th style="border-top-right-radius: 20px;" class="historicoth">Motivo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="historicoModal.length == 0" >
                                                <td class="emptyTableHistorico" colspan="3">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(item, index) in historicoModal">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="pl-7 historicotd"> <div class="textoTablaHistorico">{{item.estatus}}</div> </td>
                                                        <td class="historicotd pt-4 pb-0">
                                                            <div class="textoTablaHistorico">{{item.responsable.nombre + ' ' + item.responsable.apellido_paterno + ' ' + (item.responsable.apellido_materno != null ? item.responsable.apellido_materno : '' )}}</div>
                                                        </td>
                                                        <td class="historicotd"> <div class="textoTablaHistorico">{{item.motivo}}</div> </td>
                                                    </tr>
                                                    <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/PeriodosIncidencias';
import queriesEmpresas from '@/queries/Empresas';
import queriesTipoNominas from '@/queries/TipoNominas';
import queriesPeriodos from '@/queries/Periodos';

import Notify from '@/plugins/notify';
import apiClientes from "@/api/clientes";
import apiTipoNomina from "@/api/nominas/tipoNomina";
import apiUsuarios from "@/api/usuarios";
import apiPeriodos from '@/api/nominas/periodos';
import apiPeriodoIncidencia from '@/api/periodoIncidencia';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'periodo',
                    filterable: true
                },
                {
                    label: 'Inicio de captura',
                    name: 'inicio_captura',
                    filterable: true
                },
                {
                    label: 'Fin de captura',
                    name: 'final_captura',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'comentarios',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            brigada           : {
                id                      : null,
                nombre                  : null
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            periodoIncidencia       :{
                id                  : 0,
                cliente_id          : null,
                empresa_id          : null,
                tipo_nomina_id      : null,
                periodo_id          : null,
                usuario_captura_id  : null,
                usuario_autoriza_id : null,
                periodo             : "",
                inicio_captura      : null,
                final_captura       : null,
                departamentos       : [],
                estatus             : null,
                comentarios         : null,
            },
            loadingModal                : false,
            itemsTiposNominas           : [],
            tipo_nomina_id              : null,
            itemsPeriodos               : [],
            periodo_id                  : null,
            calendar_final              : false,
            calendar_inicio             : false,
            inicio_captura              : null,
            final_captura               : null,
            fecha_inicio                : null,
            fecha_final                 : null,
            itemsRegistrosPatronales    : [],
            modelRegistrosPatronales    : [],
            itemsEmpresas               : [],
            modelEmpresas               : [],
            itemsDepartamentos          : [],
            modelDepartamentos          : [],
            itemsUsuarios               : [],
            itemsClientes               : [],
            departamentosEmpresas       : [],
            itemsUsuariosAutorizan      : [],
            itemsUsuariosCapturan       : [],
            usuario_captura_id          : null,
            usuario_autoriza_id         : null,
            periodo_incidencias         : null,
            rol                         : null,
            datosLogin                  : null,
            cliente_value               : null,
            empresa_value               : null,
            registros_patronales_id     : false,
            empresas_id                 : false,
            departamentos_id            : false,
            periodo_update              : null,
            periodo_id_update           : null,
            isLoadingIncidencias        : false,
            dialogIncidencia            : false,
            tituloModalIncidencia       : '',
            pCliente                    : null,
            pTipo_nomina                : null,
            pPeriodo                    : null,
            pPeriodoIncidencia          : null,
            pCalendar_inicio_periodo    : false,
            pCalendar_final_periodo     : false,
            pInicio_captura_periodo     : null,
            pFinal_captura_periodo      : null,
            pCalendar_inicio            : false,
            pCalendar_final             : false,
            pInicio_captura             : null,
            pFinal_captura              : null,
            pObservaciones              : null,
            estatus                     : null,
            periodo_id_busqueda         : null,
            tipo_nomina_id_busqueda     : null,


            expanded: [],
            singleExpand: false,

            dialogHistorico         : false,
            isLoadingHistorico      : false,
            historicoModal          : [],

            permisos :{
                create: false,
                update: false,
                delete: false,
            }

        }
    },
    watch: {
        tipo_nomina_id: function(newData, oldData){
            if (newData != null){
                if(oldData!= null){
                    this.itemsDepartamentos = [];
                    this.modelDepartamentos = [];
                    this.periodo_id = null;
                }
                if(!this.estatus){
                    this.getPeriodos();
                    this.getDepartamentos();
                }
                else{
                    this.getPeriodosBusqueda();
                }
                
            }
        },
        periodo_id: function(val){
            if(val!=null){
                if((this.accion =='add') || (this.accion == 'update' && this.periodo_id_update != this.periodo_id)){
                    this.getPeriodoIncidencia(val)
                }
                if(this.accion == 'update'){
                    if(this.periodo_id_update == this.periodo_id){
                        this.periodoIncidencia.periodo = this.periodo_update;
                    }
                }
            }
        },
        tipo_nomina_id_busqueda: function(newVal, oldVal){
            if(newVal != null){
                this.getPeriodosBusqueda();
            }
        },
        cliente_value: function(val){
            if(val != null){
                this.getEmpresas();
                
            }
        },
        empresa_value: function(newData, oldData){
            if(newData != null){
                this.getTiposNominas(newData);
                this.getUsuarios(newData);

                if(oldData != null){
                    this.tipo_nomina_id = null;
                    this.periodo_id = null;
                    this.itemsDepartamentos = [];
                    this.modelDepartamentos = [];
                }
            }
        },
    },
    methods: {
        async listar(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if(this.rol == "root"){
                await this.getClientes();
            }
            else if(this.rol == "admin"){
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
            }
            else{
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            }
            
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        async abrirModal(accion, data = null){
            await this.resetValues();
            this.accion     = accion;
            this.isLoadingIncidencias  = true;
            

            if (this.rol == "root") {
                await this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }


            if(accion == "add"){
 
                this.tituloModal    = "Periodo de complementos";
                this.imagen         = "/static/modal/periodoIncidenciaCreate.svg";
                this.estatus        = false;
                this.isLoadingIncidencias  = false;
            } else {
                this.tituloModal = "Actualizar Periodo de complementos";
                this.imagen         = "/static/modal/periodoIncidenciaUpdate.svg";
                this.estatus  = (data.estatus == 'AUTORIZADO' || data.estatus == 'RECHAZADO') ? true : false;

                if(this.estatus){
                    this.tituloModal    = "Periodo de complementos";
                }


                this.periodoIncidencia.id                   = data.id;              
                this.periodoIncidencia.cliente_id           = data.cliente_id;
                this.cliente_value                          = data.cliente_id;
                this.periodoIncidencia.empresa_id           = data.empresa_id;
                this.empresa_value                          = data.empresa_id;
                this.periodoIncidencia.tipo_nomina_id       = data.tipo_nomina_id;
                this.tipo_nomina_id                         = data.tipo_nomina_id;
                this.periodoIncidencia.periodo_id           = data.periodo_id;
                this.periodo_id                             = data.periodo_id;
                this.periodo_id_update                      = data.periodo_id;
                this.periodoIncidencia.usuario_captura_id   = data.usuario_captura_id;
                this.periodoIncidencia.usuario_autoriza_id  = data.usuario_autoriza_id;
                this.periodoIncidencia.periodo              = data.periodo;
                this.periodo_update                         = data.periodo;
                this.periodoIncidencia.inicio_captura       = data.inicio_captura;
                this.periodoIncidencia.final_captura        = data.final_captura;
                this.periodoIncidencia.departamentos        = data.departamentos;
                this.periodoIncidencia.estatus              = data.estatus;
                this.periodoIncidencia.comentarios          = data.comentarios;
                
                setTimeout(()=>this.$refs.FechaCaptura.setDate(data.inicio_captura),200);
                setTimeout(()=>this.$refs.FechaFinCaptura.setDate(data.final_captura),200);

                
                let depto = [];
                data.departamentos.forEach((element) => {
                    depto.push({
                        id: parseInt(element["id"],10)
                    });
                });
                this.modelDepartamentos = depto;  
                this.isLoadingIncidencias  = false;
                
            }
            
            setTimeout(function(){ document.getElementById("btnModal").click();}, 100);
        },
        cerrarModal() {
            this.$apollo.queries.empresas.skip = true; // tiene que estar antes de que se cierre el modal
            this.$apollo.queries.tipo_nominas.skip = true;
            this.$apollo.queries.periodos.skip = true;
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.itemsUsuarios              = [];
            this.itemsRegistrosPatronales   = [];
            this.modelRegistrosPatronales   = [];
            this.itemsEmpresas              = [];
            this.modelEmpresas              = [];
            this.itemsDepartamentos         = [];
            this.modelDepartamentos         = [];
            this.itemsTiposNominas          = [];
            this.itemsPeriodos              = [];
            this.registros_patronales_id    = false;
            this.empresas_id                = false;
            this.departamentos_id           = false;

            this.periodoIncidencia  = {
                id                  : 0,
                cliente_id          : null,
                empresa_id          : null,
                tipo_nomina_id      : null,
                periodo_id          : null,
                usuario_captura_id  : null,
                usuario_autoriza_id : null,
                periodo             : "",
                inicio_captura      : null,
                final_captura       : null,
                departamentos       : [],
                estatus             : null,
                comentarios         : null,
            };

            if(this.$refs.FechaCaptura != undefined || this.$refs.FechaCaptura != null){
                this.$refs.FechaCaptura.resetDate();
            }
            if(this.$refs.FechaFinCaptura != undefined || this.$refs.FechaFinCaptura != null){
                this.$refs.FechaFinCaptura.resetDate();
            }
            if(this.$refs.BusquedaFechaInicio != undefined || this.$refs.BusquedaFechaInicio != null){
                this.$refs.BusquedaFechaInicio.resetDate();
            }
            if(this.$refs.BusquedaFechaFin != undefined || this.$refs.BusquedaFechaFin != null){
                this.$refs.BusquedaFechaFin.resetDate();
            }

            this.cliente_value              = null;
            this.empresa_value              = null;
            this.tipo_nomina_id             = null;
            this.periodo_id                 = null;
            this.inicio_captura             = null;
            this.final_captura              = null;
            this.periodo_id_busqueda        = null;
            this.tipo_nomina_id_busqueda    = null;

            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } 
            else if(this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
            }
        },
        setFilters() {
            this.filters = { AND:[]};

            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let tipo_nomina = this.tipo_nomina_id_busqueda;
            let periodo = this.periodo_id_busqueda;
            let fecha_inicio = this.inicio_captura;
            let fecha_final = this.final_captura;

            if(this.rol !== "root"){
                cliente = this.datosLogin.cliente_id;
            }
            if(this.rol !== "root" && this.rol !== "admin"){
                empresa = this.datosLogin.empresa_id;
            }
    
            this.isLoading = true;

            this.filters.AND.push({column:'TEMPORAL', value:false});

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.AND.push({ column: "INICIO_CAPTURA", operator: "GTE" , value: fecha_inicio});
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.AND.push({ column: "FINAL_CAPTURA", operator: "LTE" , value: fecha_final});
            }


            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }

            if(tipo_nomina != null && tipo_nomina != undefined && tipo_nomina != ""){
                this.filters.AND.push({column:'TIPO_NOMINA_ID',operator:'LIKE',value:tipo_nomina});
            }

            if(periodo != null && periodo != undefined && periodo != ""){
                this.filters.AND.push({column:'PERIODO_ID', value:periodo});
            }

            this.paginationData.numberPage = 1;
            this.$apollo.queries.periodos_incidencias.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este periodo de complementos?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPeriodoIncidencia.delete(data.id).then(response => {
                        Notify.Success("Periodo de complementos eliminado", "El periodo de complementos ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.periodos_incidencias.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },   
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.periodoIncidencia.cliente_id       = this.cliente_value;
                this.periodoIncidencia.empresa_id       = this.empresa_value;
                this.periodoIncidencia.tipo_nomina_id   = this.tipo_nomina_id;
                this.periodoIncidencia.periodo_id       = this.periodo_id;
                
                let depto   = [];
                
                if(this.accion == "add"){
                    this.modelDepartamentos.forEach((element) => {
                        depto.push({
                            id: element,
                        });
                    });
                    
                } else if(this.accion == "update"){
                    
                    if(this.departamentos_id){
                        this.modelDepartamentos.forEach((element) => {
                            depto.push({
                                id: element,
                            });
                        });
                    }else{
                        this.modelDepartamentos.forEach((element) => {
                            depto.push({
                                id: element["id"],
                            });
                        });
                    }
                }
                this.periodoIncidencia.departamentos        = depto;
                
                this.isLoading = true;
                if(this.accion === "add") {
                    this.periodoIncidencia.estatus = 'CAPTURA'
                    apiPeriodoIncidencia.store(this.periodoIncidencia)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El periodo de complementos se guardó satisfactoriamente.");
                        this.$apollo.queries.periodos_incidencias.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        console.log(err)
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPeriodoIncidencia.update(this.periodoIncidencia)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El periodo de complementos se actualizó satisfactoriamente.");
                        this.$apollo.queries.periodos_incidencias.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        console.log(err)
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        cambio_registro(){
            this.registros_patronales_id = true;
        },
        cambio_departamento(){
            this.departamentos_id = true;
        },
        async getTiposNominas(empresa_id) {
            var param={
                activo: true,
                cliente_id : this.cliente_value,
                empresa_id : empresa_id,
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo realizar la petición");
            })
        },
        getEmpresas(){
            this.$apollo.queries.empresas.skip = true;
            this.$apollo.queries.empresas.skip = false;
        },
        async getPeriodos(){
            if(this.tipo_nomina_id != null){
                var param={
                    tipo_nomina_id : this.tipo_nomina_id,
                    activo:true,
                    cerrado:false,
                    acumulado:false,
                    order:'asc',
                }
                await apiPeriodos.find(param).then((response) => {
                    let formatPeriodos = response.data;
                    this.itemsPeriodos = formatPeriodos.filter((periodo) => periodo.tipo_periodo_especial == null);
                })
                .catch (err => {
                    console.log(err);
                    Notify.ErrorToast("No se pudo realizar la petición");
                })
            }
        },
        async getPeriodosBusqueda(){
            var param={
                tipo_nomina_id : this.tipo_nomina_id_busqueda,
                activo:true,
            }
            await apiPeriodos.find(param).then((response) => {
                let formatPeriodos = response.data;
                this.itemsPeriodos = formatPeriodos.filter((periodo) => periodo.tipo_periodo_especial == null);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudo realizar la petición");
            })
        },
        async getPeriodoIncidencia(id = null){

            if(id!= null){
                var param={
                   tipo_nomina_id : this.tipo_nomina_id,
                   periodo_id:id
                }
                await apiPeriodos.getPeriodoI(param).then((response) => {
                    this.periodoIncidencia.periodo = response.data;
                })
                .catch (err => {
                    console.log(err);
                    Notify.ErrorToast("No se pudo realizar la petición");
                })
            }
        },
        async getUsuarios(empresa_id){
            let paramUsuarios = {
                activo      : true, 
                paginate    : false,
                rol_name    : this.rol,
                empresa_id  : empresa_id
            }
            await apiPeriodoIncidencia.getUsuarios(paramUsuarios).then((response) => {
                this.itemsUsuariosCapturan  = response.data.capturan; 
                this.itemsUsuariosAutorizan = response.data.autorizan;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los usuarios");
            })
        },
        async getClientes(){
            let paramClientes = {
                activo      : true, 
                paginate    : false,
                cliente_id  : this.datosLogin.cliente_id
            }
            await apiClientes.getClientes(paramClientes).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los clientes");
            })
        },
        async getDepartamentos(){
            if(this.tipo_nomina_id == null){
                return;
            }
            let paramClientes = {
                tipo_nomina_id: this.tipo_nomina_id
            }
            await apiPeriodoIncidencia.getDepartamentos(paramClientes).then((response) => {
                this.itemsDepartamentos = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los departamentos");
            })
        },
        /* resetDeptos(){
            this.itemsDepartamentos = [];
            this.modelDepartamentos = [];
            this.modelEmpresas.forEach(element => {
                var emp = this.itemsEmpresas.find(el => el.id == element);
                if(emp.departamentos.length !=0){
                    emp.departamentos.forEach(depto => {
                        this.itemsDepartamentos.push(depto);
                    });
                }
            });
        }, */
        vista_incidencias(data = null){
            this.$session.remove("periodoIncidencias");
            this.$session.set('periodoIncidencias',data);
            this.$session.set('temporal',false);
            this.$router.push('/incidenciasNominas');
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        historico(periodo){
            this.historicoModal = periodo.historicos
            this.dialogHistorico = true
        },
        cerrarModalHistorico(){
            this.historicoModal = []
            this.dialogHistorico = false
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("periodo-incidencias.store")
            this.permisos.update = permisosStorage.includes("periodo-incidencias.update")
            this.permisos.delete = permisosStorage.includes("periodo-incidencias.delete")
        },
    },
    created(){
        this.listar();
        this.permisosVista();
    },
    mounted(){
        this.filters.AND.push({column:'TEMPORAL', value:false});
        this.$apollo.queries.periodos_incidencias.skip = false;
    },
    apollo: {
        periodos_incidencias: {
            query       : queries.periodos_incidenciasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'id',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.periodos_incidencias;
            },
        },
        tipo_nominas: {
            query       : queriesTipoNominas.tipo_nominasQuery,
            fetchPolicy : 'network-only',
            variables(){
                return{
                    whereConditions : {AND:[{column:"CLIENTE_ID",value:this.cliente_value}]},
                    numberItems     : 10000,
                    numberPage      : 1,
                    fieldOrder      : 'id',
                }
            },
            skip() {
                return true
            },
            result({loading, error}){
                let format = this.tipo_nominas.data;
                if(this.tipo_nominas.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            }
        },
        periodos:{
            query       : queriesPeriodos.periodosQuery,
            fetchPolicy : 'network-only',
            variables(){
                return{
                    whereConditions : {AND:[{column:"TIPO_NOMINA_ID",value:this.tipo_nomina_id},
                                            {column:"CERRADO",value:false},
                                            {column:"ACUMULADO",value:false}]},
                    numberItems     : 10000,
                    numberPage      : 1,
                    fieldOrder      : 'numero',
                }
            },
            skip() {
                return true
            },
            result({loading, error}){
                this.itemsPeriodos = this.periodos.data;
            }
        },
        empresas:{
            query       : queriesEmpresas.empresasQuery,
            fetchPolicy : 'network-only',
            variables(){
                return{
                    whereCondition : {AND:[{column:"CLIENTE_ID",value:this.cliente_value}]},
                    numberItems     : 10000,
                    numberPage      : 1,
                    fieldOrder      : 'id',
                }
            },
            skip() {
                return true
            },
            result({loading, error}){
                this.itemsEmpresas = this.empresas.data;
            }
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableHistorico{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-historico {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-historico .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .historicoth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .historicotd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaHistorico{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

</style>
