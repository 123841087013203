import gql from 'graphql-tag';

const empresasTableQuery = gql`
    query empresas($whereCondition:EmpresasWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        empresas(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                razon_social,
                rfc,
                numero,
                telefono_contacto,
                logo,
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const empresasQuery = gql`
    query empresas($whereCondition:EmpresasWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        empresas(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                nombre,
                razon_social,
                rfc,
                departamentos{
                    id,
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const empresasPagadorasQuery = gql`
    query empresas($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $cliente_id: Int!) {
        empresas(first: $numberItems, page: $numberPage, activo: true, cliente_id:$cliente_id, solo_pagos: true, orderBy:[{field: $fieldOrder, order: ASC}]) {
            data {
                id,
                nombre,
                razon_social,
                rfc,
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`
const getEmpresaQuery = gql `
    query empresa($id: ID!) {
        empresa(id: $id) {
            id,
            cliente_id,
            estado_id,
            municipio_id,
            cp_id,
            colonia_id,
            nombre,
            razon_social,
            rfc,
            direccion,
            clave_empleado_auto,
            url_server_asp,
            logo,
            activo,
            clave,
            calle,
            numero,
            interior,
            nombre_contacto,
            correo_contacto,
            telefono_contacto,
            codigoPostal{
                nombre
            }
            estado{
                nombre
            }
            municipio{
                nombre
            }
        }
    }
`


const queries = { empresasTableQuery, empresasQuery, getEmpresaQuery, empresasPagadorasQuery };

export default queries;
